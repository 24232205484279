<template>
  <div class="contributions"  v-if="hasPerm('payments.view_contribution')">
    <page-header title="Extrait de compte" icon="fa fa-list-numeric" :links="getLinks()"></page-header>
    <div>
      <b-row>
        <b-col>
          <h2>
            <counter-label :counter="contributions.length" label="entrée" v-if="!isLoading(loadingName)">
            </counter-label>
          </h2>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="school-years-group"
            label="Année scolaire"
            label-for="school-years"
          >
            <b-form-select
              id="school-years"
              v-model="selectedSchoolYear"
              >
              <b-form-select-option
                :value="item"
                v-for="item in schoolYears"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            id="analytic-account-group"
            label="Analytique"
            label-for="analytic-account"
          >
            <b-form-select
              id="analytic-account"
              v-model="selectedAnalyticAccount"
              >
              <b-form-select-option
                :value="item"
                v-for="item in analyticAccounts"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName)">
      <div ref="excelTable" >
        <div><b>Contributions</b></div>
        <div class="empty-text" v-if="contributions.length === 0">
          Aucun paiement correspondant
        </div>
        <div v-if="contributions.length">
          <table
            class="table table-striped"
            title="Contributions"
          >
            <tr>
              <th>Date de saisie</th>
              <th>Date</th>
              <th>Famille</th>
              <th>Montant</th>
              <th>Mode de paiement</th>
              <th></th>
              <th colspan="3">Dépôt</th>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th>
                <span v-if="selectedAnalyticAccount">{{ selectedAnalyticAccount.name }}</span>
                &nbsp;
                <span v-if="selectedSchoolYear">{{ selectedSchoolYear.name }}</span>
              </th>
              <th class="number">{{ total | currency }}</th>
              <th colspan="5"></th>
            </tr>
            <tr v-for="contribution in contributions" :key="contribution.id">
              <td>
                <span v-if="contribution.payment">
                  {{ contribution.payment.createdOn | dateToString }}
                </span>
                <span v-if="contribution.credit">
                  {{ contribution.credit.createdOn | dateToString }}
                </span>
              </td>
              <td>
                <span v-if="contribution.payment && contribution.payment.paymentDate">
                  {{ contribution.payment.paymentDate | dateToString }}
                </span>
              </td>
              <td>
                <span v-if="contribution.payment">
                  {{ contribution.payment.entity.name }}
                </span>
                <span v-if="contribution.credit">
                  {{ contribution.credit.entity.name }}
                </span>
              </td>
              <td class="number">
                {{ contribution.amount | currency }}
              </td>
              <td>
                <span v-if="contribution.payment">
                  {{ contribution.payment.paymentMode.name }}
                </span>
                <span v-if="contribution.credit">
                  Avoir
                </span>
              </td>
              <td>
                <span v-if="contribution.payment">
                  {{ contribution.payment.number }}
                </span>
              </td>
              <td>
                <span v-if="contribution.payment">
                  {{ contribution.payment.number }}
                </span>
              </td>
              <td>
                <span v-if="contribution.payment && contribution.payment.deposit">
                  {{ contribution.payment.deposit.depositOn | dateToString }}
                </span>
              </td>
              <td>
                <span v-if="contribution.payment && contribution.payment.deposit">
                  {{ contribution.payment.deposit.number }}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="cancellations.length">
          <div><b>Avoirs générés</b></div>
          <table
            class="table table-striped"
            title="Avoirs générés"
          >
            <tr>
              <th>Date</th>
              <th>Famille</th>
              <th>Montant</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <span v-if="selectedAnalyticAccount">{{ selectedAnalyticAccount.name }}</span>
                &nbsp;
                <span v-if="selectedSchoolYear">{{ selectedSchoolYear.name }}</span>
              </th>
              <th class="number">{{ totalCancellations | currency }}</th>
            </tr>
            <tr v-for="cancellation in cancellations" :key="cancellation.id">
              <td>
                {{ cancellation.credit.createdOn | dateToString }}
              </td>
              <td>
                {{ cancellation.credit.entity.name }}
              </td>
              <td class="number">
                {{ cancellation.credit.amount | currency }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { makeCredit, makePayment, makeAnalyticAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { dateToString, currency } from '@/filters/texts'
import { sum } from '@/utils/math'
import { slugify } from '@/utils/strings'

export default {
  name: 'Contribution',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'contributions',
      contributions: [],
      cancellations: [],
      paymentsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      initialLoadingDone: false,
      schoolYears: [],
      selectedSchoolYear: null,
      analyticAccounts: [],
      selectedAnalyticAccount: null,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    selectedAnalyticAccount() {
      this.loadElements()
    },
    async selectedSchoolYear() {
      await this.loadAnalytics()
      this.selectedAnalyticAccount = null
    },
  },
  computed: {
    total() {
      return sum(this.contributions.map(elt => elt.amount))
    },
    totalCancellations() {
      return sum(this.cancellations.map(elt => elt.credit.amount))
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      await this.loadSchoolYears()
      await this.loadAnalytics()
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
    },
    async loadElements() {
      this.contributions = []
      this.cancellations = []
      if (this.selectedAnalyticAccount && this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        const id1 = this.selectedSchoolYear.id
        const id2 = this.selectedAnalyticAccount.id
        let url = '/api/contribution-analytics/' + id1 + '/' + id2 + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.contributions = resp.data.contributions.map(
            elt => {
              return {
                payment: elt.payment ? makePayment(elt.payment) : null,
                credit: elt.credit ? makeCredit(elt.credit) : null,
                amount: +elt.amount,
              }
            }
          )
          this.cancellations = resp.data.cancellations.map(
            elt => {
              return {
                id: elt.id,
                credit: makeCredit(elt.credit),
              }
            }
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length >= 1) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAnalytics() {
      if (this.selectedSchoolYear) {
        let url = '/api/analytic-accounts/?used_in=' + this.selectedSchoolYear.id
        this.analyticAccounts = []
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.analyticAccounts = resp.data.map(elt => makeAnalyticAccount(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getLinks() {
      const isActive = !this.isLoading(this.loadingName) && this.contributions.length
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.toExcel,
          icon: 'fa fa-file-excel',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'PDF',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
    },
    async toExcel() {
      const isActive = !this.isLoading(this.loadingName) && this.contributions.length
      if (isActive) {
        const docUrl = '/documents/table-to-excel/<key>/'
        const docSlug = 'compte-' + slugify(this.selectedAnalyticAccount.name) +
          '-' + slugify(this.selectedSchoolYear.name)
        const docContent = this.$refs.excelTable.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async printMe() {
      const isActive = !this.isLoading(this.loadingName) && this.contributions.length
      if (isActive) {
        const docUrl = '/documents/standard/<key>/pdf/'
        const docSlug = 'compte-' + slugify(this.selectedAnalyticAccount.name) +
          '-' + slugify(this.selectedSchoolYear.name)
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style lang="less">
</style>
